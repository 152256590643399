

// import home1 from '../img/home1.png';
import kids from '../img/kids.jpg';
import haus from '../img/haus.jpg';
import pfeil from '../img/arrow-right.svg';
//Styled Components 
import styled from 'styled-components';
import { About, Hide } from '../styles'
import com2 from '../fonts/Comfortaa-Bold.ttf';
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'
import { useState } from 'react';


const Card2 = () => {
    const [element, controls] = useScroll();
    const [isChecked, setIsChecked] = useState(false);
    const [isActive, setIsActive] = useState(false);
    // const titleAnim = {
    //     hidden: { opacity: 0 }, show: { opacity: 1, transition: { duration: 2 } }
    // }
    // const container = {
    //     hidden: { x: 100 }, show: { x: 0, transition: { duration: .75, ease: "easeOut" } }
    // }
    return (

        <Container1 variants={scrollReveal} animate={controls} initial="hidden" ref={element}>
            <Container>
                <Cards onClick={() => setIsChecked(!isChecked)}>
                    <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                        checked={isChecked} />
                    <Card className="card">
                        <Front>
                            <img className="layer" src={kids} alt="" />

                            <Text>
                                <span>
                                    <h3>
                                        Kinderbetreuung/ Nanny
                </h3>

                                </span>

                            </Text>
                            <Pfeil src={pfeil} alt="" />
                        </Front>
                        <Back>
                            <h4>Kinderbetreuung/ Nanny</h4>
                            <article>


                                <h5>
                                    Ob Nachmittagsbetreuung, Hausaufgabenbetreuung, während Ihrer Arbeitszeit oder ein paar Stunden zu zweit. Wir kümmern uns gerne um Ihre kleinen/großen Racker - zuverlässig und liebevoll.
                            </h5>
                                <h5>Oh Nein mein Racker hat bald Geburtstag!
                                Kein Problem für uns. Wir kümmern uns sehr gerne um die Vorbereitung dieses Anlasses.

</h5>
                            </article>

                            <Pfeil src={pfeil} alt="" />
                        </Back>
                    </Card>
                </Cards>
                <Cards onClick={() => setIsActive(!isActive)}>
                    <Check type="checkbox" onChange={(event) => setIsChecked(event.currentTarget.checked)}
                        checked={isActive} />
                    <Card className="card">
                        <Front>
                            <img className="layer" src={haus} alt="" />

                            <Text>
                                <span>
                                    <h3>
                                        Berufsbetreuung
                </h3>

                                </span>

                            </Text>
                            <Pfeil src={pfeil} alt="" />
                        </Front>
                        <Back>
                            <h4>Berufsbetreuung</h4>
                            <article>


                                <h5>
                                    Wir als Berufsbetreuer unterstützen Sie, wenn Sie oder ihre Angehörigen aufgrund einer Krankheit oder Behinderung die rechtlichen Angelegenheiten nicht mehr selbstständig regeln können.
                                    </h5>
                                <h5>
                                    Sei es Vermögensverwaltung, Gesundheitsfürsorge oder Wohnungsangelegenheiten.
                                    Unsere Aufgabe ist es Sie in Ihren Rechten und Interessen zu vertreten.

</h5>
                            </article>

                            <Pfeil src={pfeil} alt="" />
                        </Back>
                    </Card>
                </Cards>
            </Container>
        </Container1>
    );
};

// Styled Components
const Container1 = styled.div`
background-color:#FAF4F4;
padding:6rem 0rem;

`;
const Container = styled.div`
display: flex;
justify-content:space-between;
width:80%;
margin: 8rem auto;
@media (max-width: 1095px){
      
      flex-direction:column;
      width:100%;
      margin: 1rem auto;
          
       };

`;

const Cards = styled.div`
display: flex;
justify-content: center;
perspective: 1000px;
	transform-style: preserve-3d;
	cursor: pointer;
    & :checked +.card{
        transform: rotateY(175deg);
       
    }

`;
const Check = styled.input`
display:none;

&:checked + .card{
    transform: rotateY(180deg);
}
`;

const Card = styled.div`
/* background-color:#fff; */
position: relative;
z-index:-5;
width: 80%;
height:50rem;
border-radius: 2rem;
box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
transform-style: preserve-3d;
	transition: all 0.5s ease-in-out;
@media (min-width: 2300px){
    height:50rem;
}

@media (max-width: 1095px){
      margin: 5rem 0;
      height:59rem;
          
    };


`;
const Front = styled.div`
position: relative;
background-color:#fff;
border-radius: 2rem;
width: 100%;
height:100%;
backface-visibility: hidden;

.layer{
    width: 100%;
    object-fit: cover;
    border-radius: 2rem 2rem 0 0;
};

`;


const Pfeil = styled.img`
position: absolute;

width: 2rem;

bottom:5%;
right:8%;
`;


const Text = styled.div`
display:flex;
justify-content: space-between;

width:80%;

margin:3rem auto 0rem;
@media (max-width: 1095px){
        width:100%;
          
          margin:2rem auto 0rem;
          
    };

h3{
    font-size:2.5rem;
}

p{
    font-size:1rem;
    color: #707070;
}
`;

const Back = styled.div`
@font-face {
    font-family: "com2";
    src: url(${com2}) format("truetype")
  }
  background-color:#fff;

  border-radius: 2rem;
position: absolute;
width: 100%;
height:100%;
top:0;
left:0;

backface-visibility: hidden;
    transform: rotateY(180deg);

h4{
    font-family: 'com2';
    font-size:2.5rem;
    width: 90%;
    margin: 3.5rem auto 2rem;
    @media (max-width: 1095px){
      
      font-size:2rem;
        
  };
}    

article{
    background:#FAF4F4;
    
    width:80%;
    margin: 6rem auto;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;

    @media (max-width: 1095px){
        margin: 1rem auto;
        width:90%;
        padding: .5rem 1.2rem;
}

    h5{
        font-size:1.2rem;
        line-height:2rem;
        margin: 2rem 0rem;
    }
}


`;


export default Card2;