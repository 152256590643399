import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import { AiOutlineFileDone } from "react-icons/ai";

export const SidebarData = [
    {
        title: 'Start',
        path: '/',
        icon: <AiIcons.AiFillHome />,
        cName: 'nav-text'
    },
    {
        title: 'Dienstleistungen',
        path: '/work',
        icon: <IoIcons.IoMdPeople />,
        cName: 'nav-text'
    },
    {
        title: 'Impressum',
        path: '/contact',
        icon: <FaIcons.FaEnvelopeOpenText />,
        cName: 'nav-text'
    },

    {
        title: 'Termine',
        path: '/termine',
        icon: <AiOutlineFileDone />,
        cName: 'nav-text'
    }
];