


//Styled Components 
import styled from 'styled-components';
import { About, Hide } from '../styles'
//Framer Motion
import { motion } from "framer-motion";
import { titleAnim, fade, photoAnim } from "../animation";
import { scrollReveal } from "../animation";
import { useScroll } from './useScroll'


const Vorteile = () => {
    const [element, controls] = useScroll();
    return (

        <Container1 variants={scrollReveal} animate={controls} initial="hidden" ref={element}>

            <article>
                <h3>
                    Diese Dienstleistung richtet sich an alle Altersgruppen, die Hilfe im Alltag benötigen.
                </h3>
                <h4>Ihre Vorteile:</h4>
                <span>

                    <ul>

                        <li>Sie und Ihre Angehörigen können in der gewohnten Umgebung bleiben</li>
                        <li>Alltagshilfe für Jung bis Alt</li>
                        <li>Vertrauenswürdige und zuverlässige Hilfe</li>
                        <li>Fachkräfte mit Nachweis vom polizeilichen Führungszeugnis</li>
                        <li>Ein persönlicher Ansprechpartner</li>

                    </ul>
                    <ul>

                        <li>Von der Steuer absetzbar</li>
                        <li>Abrechnung über die Krankenkasse oder Pflegekasse möglich</li>
                        <li>Keine Aufnahmegebühr</li>
                        <li>Keine Vertragsbindung</li>
                        <li>Keine Mindestabnahme</li>
                        <li>Keine versteckten Zusatzkosten</li>
                    </ul>
                </span>

                <h4>
                    Umgang mit Beschwerden:
                </h4>
                <p>Wir werden für Sie zufriedenstellend handeln, jede Beschwerde wird aufgenommen sowie ernstgenommen.<br /> Sie rufen uns an oder schreiben uns einfach eine E-Mail wir setzen uns dann mit Ihnen schnellstmöglich in Kontakt.</p>

                <h4>
                    Verfügbarkeit:
                </h4>
                <ul className="daten">
                    <li><h5>Arbeitszeit: </h5>  <p>Montag bis Freitag 08:00 – 17:00 Uhr</p></li>


                </ul>
                <Button href="https://outlook.office365.com/owa/calendar/HomeandFamily@HomeandFamily-mg.de/bookings/" target="_blank">Termin buchen</Button>
            </article>


        </Container1>
    );
};

// Styled Components
const Container1 = styled.div`
width:80%;
padding: 3rem 0;
margin:2rem auto 8rem;
@media (max-width: 1095px){
      
    width:90%;
    margin:2rem auto 4rem;
          
       };
article{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 6rem 2rem;
    @media (max-width: 1095px){
      
        padding: 3rem 2rem;
            
         };

    h3{
        margin: 1rem 0rem 6rem;
        color: #C19C9C;
        @media (max-width: 1095px){
      
      font-size:1.5rem;
      margin: .5rem 0rem 1rem;
            
         };
    }
    h4{
               font-size: 1.8rem;
               margin: 4rem 0rem 2rem;
               @media (max-width: 1095px){
      
      font-size:1.5rem;
      margin: 2rem 0rem 2rem;
            
         };
           }
    p{
        color:#707070;
       
    }
    span{
        display: flex;
        justify-content: space-evenly;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        @media (max-width: 1095px){
      
      width:80%;
         };
         
    @media (max-width: 500px){
      flex-direction: column;
      width:110%;
         };

        ul{
            margin: 2rem;
            @media (max-width: 1095px){
      
      font-size:1rem;
            
         };
            
            li{
                font-size: 1.5rem;
                margin-bottom: 2rem;
                color:#707070;
                @media (max-width: 1095px){
      width:55%;
      font-size:1.1rem;
            
         };
         @media (max-width: 500px){
      width:100%;
      font-size:1.3rem;
      line-height:2rem;
            
         };
            }
        }
    }

    .daten{
        width: 60%;
        list-style: none;
        @media (max-width: 1095px){
      
      width:100%
            
         };
        li{
            display: flex;
            justify-content: space-between;
            margin-bottom: 2rem;
           
           h5{
               font-size: 1.5rem;
               @media (max-width: 1095px){
      
      font-size:1rem;
            
         };
           }
          

            p{
                font-size: 1.5rem;
                color:#707070;
                margin: 0;
                padding: 0;
                @media (max-width: 1095px){
      
                  font-size:1rem;
                  width:30%;
            
         };
            }
        }
    }
};




`;

const Button = styled.a`
 display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  overflow: hidden;
  z-index: 1;
color: #fff;
text-decoration:none;
  padding: 0.7em 1.7em;
  cursor: pointer;
  font-size: 18px;
  border-radius: 0.5em;
  background: #3f4b61;
  border: 1px solid #e8e8e8;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  margin-top:2rem;
  
  
`;







export default Vorteile;